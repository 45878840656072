
@import url(https://fonts.googleapis.com/css?family=Saira+Stencil+One);


body{
  font-family: "Saira Stencil One", cursive!important;
}

#author{
  font-size: 2rem;
  transition: font-size 0.3s ease-in-out;

  
}

#author:hover{
  font-size: 2.5rem;
}

.animated div {
  display:inline-block;
  overflow:hidden;
  white-space:nowrap;
}
.logotext{
  font-size: 4.5rem;
  color:#999;
  align-self: flex-start;
 
  
}


.logo{
  display: flex;
  align-items: flex-start;
  
  
  
}

.animated div:first-of-type {
  animation: showup 7s infinite;
}

.animated div:last-of-type {
  width:0px;
  animation: reveal 7s infinite;
}

.animated div:last-of-type span {
  margin-left:-355px;
  animation: slidein 7s infinite;
}

@keyframes showup {
    0% {opacity:0;}
    20% {opacity:1;}
    80% {opacity:1;}
    100% {opacity:0;}
}

@keyframes slidein {
    0% { margin-left:-800px; }
    20% { margin-left:-800px; }
    35% { margin-left:0px; }
    100% { margin-left:0px; }
}

@keyframes reveal {
    0% {opacity:0;width:0px;}
    20% {opacity:1;width:0px;}
    30% {width:355px;}
    80% {opacity:1;}
    100% {opacity:0;width:355px;}
}

.tmp {
    margin-left: 10px;
  
  color:#999;
  margin-bottom: 10px;
  
}

.tmp span{
  font-size:2rem
}

.animated{
    width: 600px;
}



.main-text {
  padding: 0;
  margin: 0!important;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10% !important;
  min-height: 100vh;
  font-family: "Saira Stencil One", cursive!important;
}

video {
  position: absolute;
  /* top: 0; */
  /* left: 0; */
  width: 500px;
  height: 100%;
  object-fit: cover;
}
h2 {
  /* position: absolute;
  top: 0;
  left: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  font-weight: 400!important;
  font-size: 6rem!important;
  text-transform: uppercase;
  background-color: #ffffff!important;
  mix-blend-mode: screen;
}

#icon-text{
  flex-direction: row;
}
.by{
  font-size: 1.25rem;
  color:#999;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 10%;
  flex-direction: row;
}

@media screen and (max-width: 800px) {
  #icon-text {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .animated{
    display: flex;
    justify-content: center;
    align-items: baseline ;
  }
  h2{
    font-size: 3rem !important
  }
  video{
    width: 300px;
    height: 300px;
  }
  .main-text{
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: auto;
    margin-right: auto;
    margin: 0!important;
  }
  .logotext{
    font-size: 2rem;
  }
  .tmp span{
    font-size: 1rem;
  }

}